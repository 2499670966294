export const CSRF_TOKEN_REQUEST = "CSRF_TOKEN_REQUEST";
export const CSRF_TOKEN_SUCCESS = "CSRF_TOKEN_SUCCESS";
export const CSRF_TOKEN_FAIL = "CSRF_TOKEN_FAIL";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_RESET = "USER_LOGIN_RESET";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST";
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS";
export const USER_FORGOT_PASSWORD_FAIL = "USER_FORGOT_PASSWORD_FAIL";

export const USER_INFO_REQUEST = "USER_INFO_REQUEST";
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";
export const USER_INFO_FAIL = "USER_INFO_FAIL";
export const USER_INFO_RESET = "USER_INFO_RESET";

export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";
export const USER_PROFILE_RESET = "USER_PROFILE_RESET";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const USER_EMAIL_REQUEST = "USER_EMAIL_REQUEST";
export const USER_EMAIL_SUCCESS = "USER_EMAIL_SUCCESS";
export const USER_EMAIL_FAIL = "USER_EMAIL_FAIL";

export const USER_EMAIL_RESEND_REQUEST = "USER_EMAIL_RESEND_REQUEST";
export const USER_EMAIL_RESEND_SUCCESS = "USER_EMAIL_RESEND_SUCCESS";
export const USER_EMAIL_RESEND_FAIL = "USER_EMAIL_RESEND_FAIL";

export const USER_CHANGE_PASSWORD_REQUEST = "USER_CHANGE_PASSWORD_REQUEST";
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS";
export const USER_CHANGE_PASSWORD_FAIL = "USER_CHANGE_PASSWORD_FAIL";
export const USER_CHANGE_PASSWORD_RESET = "USER_CHANGE_PASSWORD_RESET";

export const USER_CHANGE_EMAIL_REQUEST = "USER_CHANGE_EMAIL_REQUEST";
export const USER_CHANGE_EMAIL_SUCCESS = "USER_CHANGE_EMAIL_SUCCESS";
export const USER_CHANGE_EMAIL_FAIL = "USER_CHANGE_EMAIL_FAIL";
export const USER_CHANGE_EMAIL_RESET = "USER_CHANGE_EMAIL_RESET";
