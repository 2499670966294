import { Link } from "react-router-dom";
import styled from "styled-components";

export const Button = styled.input`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    padding: 8px 16px;
    align-self: stretch;

    background: #92b4ec;
    border-radius: 16px;

    border: none;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    color: #ffffff;
    cursor: pointer;
    &:enabled {
        &:active {
            background: transparent;
            padding: 5px 16px;
            border: 3px solid #92b4ec;
            color: #92b4ec;
        }
    }

    &:disabled {
        background: #b9c0c8;
        cursor: not-allowed;
    }
`;

export const ButtonLink = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    padding: 8px 16px;
    align-self: stretch;

    background: #92b4ec;
    border-radius: 16px;

    border: none;
    width: ${({ width }) => (width ? width : "auto")};

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    text-decoration: none;

    color: #ffffff;
    cursor: pointer;

    &:active {
        background: transparent;
        padding: 5px 16px;
        border: 3px solid #92b4ec;
        color: #92b4ec;
    }

    &:disabled {
        background: #b9c0c8;
        cursor: not-allowed;
    }
`;
