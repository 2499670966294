import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 7px;
`;

const Message = styled.h3`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
`;

const StyledLink = styled(Link)`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #92b4ec;

    text-decoration: none;
`;
const MoveTo = { Container, Message, StyledLink };

export default MoveTo;
