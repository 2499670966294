import * as constants from "../constants/departmentConstants";
import axios from "axios";

export const getDepartments = (keyword, descending) => async (dispatch) => {
    try {
        dispatch({ type: constants.DEPARTMENTS_REQUEST });
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY_URL}/api/departments/search?keyword=${keyword}&descending=${descending}`
        );
        dispatch({ type: constants.DEPARTMENTS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: constants.DEPARTMENTS_FAIL,
            payload:
                error.response && error.response.data.errors
                    ? error.response.data.errors
                    : error.message,
        });
    }
};

export const getDepartment = (id) => async (dispatch) => {
    try {
        dispatch({ type: constants.DEPARTMENT_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_PROXY_URL}/api/departments/${id}`);
        dispatch({ type: constants.DEPARTMENT_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: constants.DEPARTMENT_FAIL,
            payload:
                error.response && error.response.data.errors
                    ? error.response.data.errors
                    : error.message,
        });
    }
};
