import styled from "styled-components";
import Input from "../components/Styles/Input.styled";
import MoveTo from "../components/Styles/MoveTo.styled";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useState } from "react";
import { Button } from "../components/Styles/Button.styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userActions";
import { useNavigate } from "react-router-dom";

const StyledMain = styled.main`
    box-sizing: border-box;
    margin: 0 auto;
    padding: 64px 0;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 64px 122px;

    max-width: 582px;
    height: 587px;
    border-radius: 32px;
    background: #f2f2f2;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
`;

const StyledTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 57px;
    color: #000000;
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

function LoginPage() {
    const navigate = useNavigate();
    const dispath = useDispatch();
    const userLogin = useSelector((state) => state.userLogin);
    const { error, loading, isLoggedIn } = userLogin;

    const [show, setShow] = useState(false);
    const handleVisibility = () => {
        setShow(!show);
    };
    const { t } = useTranslation("LoginPage");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isEmail, setIsEmail] = useState(false);
    const [isPassword, setIsPassword] = useState(false);

    const [enabled, setEnabled] = useState(false);

    const handleLogin = (e) => {
        e.preventDefault();
        dispath(login(email, password));
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);
        if (e.target.value === "") {
            setIsEmail(false);
        } else {
            setIsEmail(true);
        }
    };

    const handelPassword = (e) => {
        setPassword(e.target.value);
        if (e.target.value === "") {
            setIsPassword(false);
        } else {
            setIsPassword(true);
        }
    };

    const checkForm = useCallback(() => {
        if (!isEmail || !isPassword) {
            setEnabled(false);
        } else {
            setEnabled(true);
        }
    }, [isEmail, isPassword]);

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/");
        }
        checkForm();
    }, [email, password, checkForm, isLoggedIn, navigate]);

    return (
        <StyledMain>
            <Container>
                <StyledTitle>{t("title")}</StyledTitle>
                <StyledForm action="">
                    <Input.Container>
                        <Input.Content>
                            <Input.StyledLabel htmlFor="email">
                                {t("email")}
                            </Input.StyledLabel>
                            <Input.Wrap>
                                <Input.Field
                                    type="email"
                                    id="email"
                                    placeholder="username@email.com"
                                    onChange={handleEmail}
                                />
                            </Input.Wrap>
                            {error && error.email ? (
                                <Input.Message>{t(error.email)}</Input.Message>
                            ) : (
                                <Input.Message>{t(error)}</Input.Message>
                            )}
                        </Input.Content>
                    </Input.Container>
                    <Input.Container>
                        <Input.Content>
                            <Input.StyledLabel htmlFor="password">
                                {t("password")}
                            </Input.StyledLabel>
                            <Input.Wrap>
                                <Input.Field
                                    type={show ? "text" : "password"}
                                    id="password"
                                    placeholder="Password"
                                    autoComplete="on"
                                    onChange={handelPassword}
                                />
                                <Input.Icon
                                    icon={show ? faEye : faEyeSlash}
                                    onClick={handleVisibility}
                                    size="2x"
                                />
                            </Input.Wrap>
                            {error && error.password && (
                                <Input.Message>
                                    {t(error.password)}
                                </Input.Message>
                            )}
                        </Input.Content>
                        {/* <Input.Find to="/forgot-password">
                            {t("forgot-password")}
                        </Input.Find> */}
                    </Input.Container>
                    <Input.Container>
                        <Button
                            type="submit"
                            value={t("login")}
                            onClick={handleLogin}
                            disabled={!enabled ? 1 : 0}
                        />
                    </Input.Container>
                </StyledForm>
                <MoveTo.Container>
                    <MoveTo.Message>{t("no-account")}</MoveTo.Message>
                    <MoveTo.StyledLink to="/register">
                        {t("register")}
                    </MoveTo.StyledLink>
                </MoveTo.Container>
            </Container>
        </StyledMain>
    );
}

export default LoginPage;
