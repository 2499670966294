import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import {
    departmentReducer,
    departmentsReducer,
} from "./reducers/departmentReducers";
import { moduleAverageReducer, moduleReducer } from "./reducers/moduleReducer";
import {
    userEmailVerifyReducer,
    userForgotPasswordReducer,
    userInfoReducer,
    userLoginReducer,
    userRegisterReducer,
    userEmailResendReducer,
    userProfileReducer,
    userChangePassowrdReducer,
    userUpdateProfileReducer,
    userChangeEmailReducer,
} from "./reducers/userReducers";

const reducer = combineReducers({
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userForgotPassword: userForgotPasswordReducer,
    userInfo: userInfoReducer,
    userProfile: userProfileReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userEmailVerify: userEmailVerifyReducer,
    userEmailResend: userEmailResendReducer,
    userChangePassword: userChangePassowrdReducer,
    userChangeEmail: userChangeEmailReducer,

    departments: departmentsReducer,
    department: departmentReducer,
    module: moduleReducer,
    moduleAverage: moduleAverageReducer,
});

const userLoggedIn = localStorage.getItem("isLoggedIn")
    ? JSON.parse(localStorage.getItem("isLoggedIn"))
    : false;

const initialState = {
    userLogin: {
        isLoggedIn: userLoggedIn,
    },
};

const middleware = [thunk];

export const store = configureStore({
    reducer,
    preloadedState: initialState,
    middleware,
});
