import { Link } from "react-router-dom";
import styled from "styled-components";
import pc from "../assets/images/pc.svg";
import tablet from "../assets/images/tablet.svg";
import mobile from "../assets/images/mobile.svg";
import ChangeLanguage from "./ChangeLanguage";
import { useTranslation } from "react-i18next";
import SimpleFooter from "./SimpleFooter";

const StyledFooter = styled.footer`
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
`;

const Main = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 172px 0 184px 0;

    position: relative;

    box-sizing: border-box;
    background-image: url(${pc});
    background-repeat: repeat-x;
    background-position: center;
    background-size: 576px 537px;
    height: 537px;

    /* @media screen and (max-width: 1439px) {
        background-image: url(${tablet});
        padding: 172px 123px 214px;
        row-gap: 64px;
        background-size: 576px 774px;
        height: 774px;
    } */

    @media screen and (max-width: 1439px) {
        background-image: url(${mobile});
        padding: 102px 30px 192px 30px;

        background-size: 576px 888px;
        height: 888px;
        row-gap: 30px;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    position: relative;

    width: 1194px;

    /* @media screen and (max-width: 1439px) {
        display: grid;
        grid-template-columns: 1fr 276px;
        justify-content: space-between;
        row-gap: 16px;
        width: 100%;
    } */

    @media screen and (max-width: 1439px) {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 32px;
        width: auto;
    }
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: ${({ width }) => (width ? width : "auto")};
    gap: 8px;

    h1 {
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 43px;
        color: #ffffff;
    }

    ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        gap: 4px;

        li {
            &:not(.center) {
                text-align: left;
            }
        }
    }

    @media screen and (max-width: 1439px) {
        align-self: stretch;
        /* flex-grow: 1; */
    }
`;

const Anchor = styled.a`
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    align-self: left;
    width: 300px;

    text-decoration: none;

    transition: 0.2s;
    &:hover {
        color: #606060;
    }
`;

const StyledLink = styled(Link)`
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;

    text-decoration: none;

    transition: 0.2s;

    &:hover {
        color: #606060;
    }
`;

function FullFooter() {
    const { t } = useTranslation("Footer");

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <StyledFooter>
            <Main>
                <Container>
                    <Section>
                        <h1>{t("full.about.title")}</h1>
                        <ul>
                            <li>
                                <StyledLink
                                    to="/introduce"
                                    onClick={scrollToTop}
                                >
                                    {t("full.about.options.uclks")}
                                </StyledLink>
                            </li>
                            <li>
                                <StyledLink
                                    to="/committee"
                                    onClick={scrollToTop}
                                >
                                    {t("full.about.options.committee")}
                                </StyledLink>
                            </li>
                            <li>
                                <StyledLink
                                    to="/constitution"
                                    onClick={scrollToTop}
                                >
                                    {t("full.about.options.constitution")}
                                </StyledLink>
                            </li>
                            <li>
                                <StyledLink
                                    to="/membership"
                                    onClick={scrollToTop}
                                >
                                    {t("full.about.options.membership")}
                                </StyledLink>
                            </li>
                            <li>
                                <StyledLink to="/faq" onClick={scrollToTop}>
                                    {t("full.about.options.faq")}
                                </StyledLink>
                            </li>
                        </ul>
                    </Section>

                    {/* <Section width="126px">
                        <h1>{t("full.event.title")}</h1>
                        <ul>
                            <li>
                                <StyledLink
                                    to="/announcement"
                                    onClick={scrollToTop}
                                >
                                    {t("full.event.options.announcement")}
                                </StyledLink>
                            </li>
                            <li>
                                <StyledLink
                                    to="/calander"
                                    onClick={scrollToTop}
                                >
                                    {t("full.event.options.calendar")}
                                </StyledLink>
                            </li>
                            <li>
                                <StyledLink to="/photos" onClick={scrollToTop}>
                                    {t("full.event.options.gallery")}
                                </StyledLink>
                            </li>
                        </ul>
                    </Section> */}

                    <Section>
                        <h1>{t("full.useful-info.title")}</h1>
                        <ul>
                            <li>
                                <StyledLink
                                    to="/module-feedback"
                                    onClick={scrollToTop}
                                >
                                    {t("full.useful-info.options.module-feedback")}
                                </StyledLink>
                            </li>
                        </ul>
                    </Section>

                    <Section>
                        <h1>{t("full.contact.title")}</h1>
                        <ul>
                            <li>
                                <Anchor href="mailto:uclks.committee@gmail.com">
                                    {t("full.contact.options.email")}
                                </Anchor>
                            </li>
                            <li>
                                <Anchor href="https://goo.gl/maps/3TkvFATx4XkU3uXP6">
                                    {t("full.contact.options.address")}
                                </Anchor>
                            </li>
                        </ul>
                    </Section>

                    <Section>
                        <ChangeLanguage />
                    </Section>
                </Container>
            </Main>
            <SimpleFooter />
        </StyledFooter>
    );
}

export default FullFooter;
