import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getDepartment } from "../actions/departmentActions";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { DEPARTMENT_RESET } from "../constants/departmentConstants";
import Ratings from "../components/Ratings.styled";
import OptionSelect from "../components/Styles/OptionSelect";
import Info from "../components/Info";
import { useTranslation } from "react-i18next";

const StyledMain = styled.main`
    box-sizing: border-box;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;

    @media screen and (max-width: 768px) {
        margin: 30px;
        padding: 0;
        gap: 30px;
    }
`;

const Title = styled.h1`
    box-sizing: border-box;
    text-align: left;
    padding: 16px 32px;

    background: #92b4ec;
    border-radius: 16px;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 57px;

    color: #ffffff;

    background: #92b4ec;
    border-radius: 16px;
    width: 785px;

    @media screen and (max-width: 768px) {
        padding: 16px 32px;
        width: 315px;

        font-size: 32px;
        line-height: 38px;
    }
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    @media screen and (max-width: 768px) {
        align-items: flex-start;
        gap: 15px;

        width: 315px;
    }
`;

const CardsHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 32px 0px 0px;

    width: 1194px;

    @media screen and (max-width: 768px) {
        width: auto;
    }
`;

const OptionSelectCurrent = styled.h2`
    text-align: right;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 43px;
    color: #000000;
    width: ${({ width }) => (width ? width : "auto")};

    @media screen and (max-width: 768px) {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        width: auto;
    }
`;

const HeaderRatings = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 856px;

    h2 {
        font-family: "Pretendard Variable";
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 43px;
        color: #000000;

        text-align: left;
    }

    .en {
        font-size: 32px;
        line-height: 38px;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const Cards = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 30px;

    width: 1194px;

    @media screen and (max-width: 768px) {
        padding: 0px;
        gap: 15px;

        width: 315px;
    }
`;

const Card = styled(Link)`
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 32px;
    gap: 30px;

    width: 1194px;

    background: #f2f2f2;
    border-radius: 16px;

    text-decoration: none;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 16px 32px;
        gap: 15px;

        width: 315px;

        background: #f2f2f2;
        border-radius: 16px;
    }
`;

const CardTitle = styled.h2`
    width: 244px;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;

    color: #000000;
    overflow-wrap: break-word;

    @media screen and (max-width: 768px) {
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        width: auto;
        align-self: stretch;
    }
`;

const RatingsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    flex-grow: 1;

    @media screen and (max-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }
`;

const CardRating = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    h3 {
        display: none;
        font-family: "Pretendard Variable";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;

        color: #000000;

        @media screen and (max-width: 768px) {
            display: inline;
        }
    }
`;

function DepartmentPage() {
    const dispatch = useDispatch();
    const id = useLocation().pathname.split("/")[3];
    const department = useSelector((state) => state.department);
    const { department: departmentInfo } = department;
    const { t, i18n } = useTranslation("DepartmentPage");

    const [options, setOptions] = useState([]);

    const [option, setOption] = useState(1);
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionClick = (option) => {
        setOption(option);
        setIsOpen(false);
    };

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        dispatch(getDepartment(id));
        return () => {
            dispatch({ type: DEPARTMENT_RESET });
        };
    }, [dispatch, id]);

    useEffect(() => {
        if (departmentInfo) {
            const options = [
                ...new Set(
                    departmentInfo?.modules?.map(
                        (module) => module.pivot.study_year
                    )
                ),
            ].sort();
            setOptions(options);
            setOption(options[0]);
        }
    }, [departmentInfo]);

    return (
        <StyledMain>
            <Title>{departmentInfo?.name}</Title>
            <MainContainer>
                <CardsHeaderContainer>
                    <OptionSelect.Container>
                        <OptionSelect.Button
                            type="button"
                            className={isOpen ? "clicked" : ""}
                            onClick={(e) => setIsOpen((current) => !current)}
                        >
                            <OptionSelectCurrent>
                                {option === null
                                    ? t("others")
                                    : option + " " + t("year")}
                            </OptionSelectCurrent>
                            <OptionSelect.Icon
                                icon={faAngleDown}
                                className="flip"
                                size="2xl"
                            />
                        </OptionSelect.Button>
                        <OptionSelect.Options
                            className={isOpen ? "active" : ""}
                        >
                            {options?.map((option, key) => {
                                return (
                                    <OptionSelect.Option key={key}>
                                        <OptionSelect.OptionText
                                            onClick={(e) =>
                                                handleOptionClick(option)
                                            }
                                        >
                                            {option === null
                                                ? t("others")
                                                : option + " " + t("year")}
                                        </OptionSelect.OptionText>
                                        {key + 1 !== options.length && (
                                            <OptionSelect.Divider />
                                        )}
                                    </OptionSelect.Option>
                                );
                            })}
                        </OptionSelect.Options>
                    </OptionSelect.Container>

                    <HeaderRatings>
                        <h2 className={i18n.language}>
                            {t("ratings.evaluation")}
                        </h2>
                        <h2 className={i18n.language}>
                            {t("ratings.module-difficulty")}
                        </h2>
                        <h2 className={i18n.language}>
                            {t("ratings.amount-of-assignments")}
                        </h2>
                        <h2 className={i18n.language}>
                            {t("ratings.exam-difficulty")}
                        </h2>
                    </HeaderRatings>
                </CardsHeaderContainer>
                <Cards>
                    {departmentInfo?.modules?.map((module, key) => {
                        return (
                            module.pivot.study_year === option && (
                                <Card
                                    to={`${module.id}`}
                                    key={module.id}
                                    onClick={scrollToTop}
                                >
                                    <CardTitle>{module.name}</CardTitle>
                                    <RatingsContainer>
                                        <CardRating>
                                            <h3>{t("ratings.evaluation")}</h3>
                                            <Ratings
                                                rating={
                                                    departmentInfo?.averages[
                                                        key
                                                    ][module.id]["evaluation"] /
                                                    2
                                                }
                                            />
                                        </CardRating>
                                        <CardRating>
                                            <h3>
                                                {t("ratings.module-difficulty")}
                                            </h3>
                                            <Ratings
                                                rating={
                                                    departmentInfo?.averages[
                                                        key
                                                    ][module.id][
                                                        "module_difficulty"
                                                    ]
                                                }
                                            />
                                        </CardRating>
                                        <CardRating>
                                            <h3>
                                                {t(
                                                    "ratings.amount-of-assignments"
                                                )}
                                            </h3>
                                            <Ratings
                                                rating={
                                                    departmentInfo?.averages[
                                                        key
                                                    ][module.id][
                                                        "amount_of_assignments"
                                                    ]
                                                }
                                            />
                                        </CardRating>
                                        <CardRating>
                                            <h3>
                                                {t("ratings.exam-difficulty")}
                                            </h3>
                                            <Ratings
                                                rating={
                                                    departmentInfo?.averages[
                                                        key
                                                    ][module.id][
                                                        "exam_difficulty"
                                                    ]
                                                }
                                            />
                                        </CardRating>
                                    </RatingsContainer>
                                </Card>
                            )
                        );
                    })}
                </Cards>
            </MainContainer>
            <Info />
        </StyledMain>
    );
}

export default DepartmentPage;
