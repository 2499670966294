import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/images/logo.png";

import GNB from "./GNB";
import Login from "./Login";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getUserInfo, getUserProfile } from "../actions/userActions";
import Logout from "./Logout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const Container = styled.header`
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const HeaderContainer = styled.div`
    height: 84px;
    background: #606060;
    border-radius: 0px 0px 16px 16px;
    align-self: stretch;
`;

const Frame = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 30px;
    gap: 16px;

    z-index: 0;
    align-self: stretch;

    @media screen and (max-width: 768px) {
        gap: normal;
    }
`;

const Nav = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 30px 0px 0px;
    gap: 22px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 64px;
    height: 84px;

    &.mobile {
        display: none;
        @media screen and (max-width: 768px) {
            display: flex;
        }
    }
`;

const StyledText = styled.p`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: #000000;

    display: flex;
    align-items: center;
    text-align: center;

    position: relative;
    z-index: 3;
`;

const Icon = styled(FontAwesomeIcon)`
    width: 32px;
    color: #ffffff;
`;

const Navigation = styled.ul`
    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 32px;
    gap: 16px;

    align-self: stretch;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
        &.active {
            display: flex;
        }
    }
`;

const IconContainer = styled.div`
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 84px;

    @media screen and (max-width: 768px) {
        display: flex;
    }
`;

const StyledNavLink = styled(NavLink)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    text-decoration: none;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;

    color: #606060;

    &.active {
        color: #000000;
    }
    &:hover {
        color: #000000;
    }
`;

const Divider = styled.div`
    height: 3px;
    background: #606060;
    border-radius: 2px;
    align-self: stretch;
`;

function Header() {
    const dispath = useDispatch();
    const userLogin = useSelector((state) => state.userLogin);
    const { isLoggedIn } = userLogin;
    const userInfo = useSelector((state) => state.userInfo);
    const { error, loading, user } = userInfo;
    const [isOpen, setIsOpen] = useState(false);

    const { t } = useTranslation("Header");

    useEffect(() => {
        if (!user && !loading) {
            if (isLoggedIn) {
                dispath(getUserProfile());
                dispath(getUserInfo());
            }
        }
    }, [dispath, user, isLoggedIn]);

    return (
        <Container>
            <HeaderContainer>
                <Frame>
                    <IconContainer>
                        <Icon
                            onClick={(e) => setIsOpen((current) => !current)}
                            icon={faBars}
                            size="2x"
                        ></Icon>
                    </IconContainer>
                    <StyledLink className="mobile" to="/">
                        <img src={logo} alt="logo" width="64px" height="64px" />
                    </StyledLink>
                    <Nav>
                        <StyledLink to="/">
                            <img
                                src={logo}
                                alt="logo"
                                width="64px"
                                height="64px"
                            />
                        </StyledLink>
                        <GNB />
                    </Nav>
                    {user ? <Logout /> : <Login />}
                </Frame>
            </HeaderContainer>
            <Navigation className={isOpen ? "active" : ""}>
                <StyledNavLink to="/" onClick={(e) => setIsOpen(false)}>
                    {t("nav.home")}
                </StyledNavLink>
                <Divider />
                <StyledNavLink
                    to="/introduce"
                    onClick={(e) => setIsOpen(false)}
                >
                    UCLKS
                </StyledNavLink>
                <Divider />
                <StyledNavLink
                    to="/module-feedback"
                    onClick={(e) => setIsOpen(false)}
                >
                    {t("nav.module-feedback")}
                </StyledNavLink>
            </Navigation>
        </Container>
    );
}

export default Header;
