import * as constants from "../constants/userConstants";
import axios from "axios";

export const login =
    (email, password, csrf = true) =>
    async (dispatch) => {
        try {
            dispatch({ type: constants.USER_LOGIN_REQUEST });

            if (csrf) {
                await axios.get(`${process.env.REACT_APP_PROXY_URL}/sanctum/csrf-cookie`);
            } else {
                console.log("yes");
            }
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY_URL}/api/login`,
                {
                    email: email,
                    password: password,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                }
            );
            dispatch({ type: constants.USER_LOGIN_SUCCESS, payload: data });
            localStorage.setItem("isLoggedIn", true);
        } catch (error) {
            dispatch({
                type: constants.USER_LOGIN_FAIL,
                payload:
                    error.response && error.response.data.errors
                        ? error.response.data.errors
                        : error.message,
            });
        }
    };

export const register =
    (name, email, password, rePassword) => async (dispatch) => {
        try {
            dispatch({ type: constants.USER_REGISTER_REQUEST });
            await axios.get(`${process.env.REACT_APP_PROXY_URL}/sanctum/csrf-cookie`);
            const { data } = await axios.post(
                `${process.env.REACT_APP_PROXY_URL}/api/register`,
                {
                    name: name,
                    email: email,
                    password: password,
                    confirmation: rePassword,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                }
            );
            dispatch({
                type: constants.USER_REGISTER_SUCCESS,
                payload: data,
                email: email,
            });
            dispatch(login(email, password, false));
        } catch (error) {
            dispatch({
                type: constants.USER_REGISTER_FAIL,
                payload:
                    error.response && error.response.data.errors
                        ? error.response.data.errors
                        : error.message,
            });
        }
    };

export const forgotPassword = (email) => async (dispatch) => {
    try {
        dispatch({ type: constants.USER_FORGOT_PASSWORD_REQUEST });
        await axios.get(`${process.env.REACT_APP_PROXY_URL}/sanctum/csrf-cookie`);
        const { data } = await axios.post(
            `${process.env.REACT_APP_PROXY_URL}/api/password/forgot`,
            {
                email,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }
        );
        dispatch({
            type: constants.USER_FORGOT_PASSWORD_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: constants.USER_FORGOT_PASSWORD_FAIL,
            payload:
                error.response && error.response.data.errors
                    ? error.response.data.errors
                    : error.message,
        });
    }
};

export const logout = () => async (dispatch) => {
    try {
        dispatch({ type: constants.USER_LOGOUT_REQUEST });
        const { data } = await axios.post(`${process.env.REACT_APP_PROXY_URL}/api/logout`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        localStorage.removeItem("isLoggedIn");
        dispatch({ type: constants.USER_LOGOUT_SUCCESS, payload: data });
        dispatch({ type: constants.USER_INFO_RESET });
        dispatch({ type: constants.USER_PROFILE_RESET });
    } catch (error) {
        dispatch({
            type: constants.USER_LOGOUT_FAIL,
            payload:
                error.response && error.response.data.errors
                    ? error.response.data.errors
                    : error.message,
        });
    }
};

export const getUserInfo = () => async (dispatch) => {
    try {
        dispatch({ type: constants.USER_INFO_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_PROXY_URL}/api/user`);
        dispatch({ type: constants.USER_INFO_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: constants.USER_INFO_FAIL,
            payload:
                error.response && error.response.data.errors
                    ? error.response.data.errors
                    : error.message,
        });
        dispatch({ type: constants.USER_LOGIN_RESET });
        localStorage.removeItem("isLoggedIn");
    }
};

export const getUserProfile = () => async (dispatch) => {
    try {
        dispatch({ type: constants.USER_PROFILE_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_PROXY_URL}/api/profile`);
        dispatch({ type: constants.USER_PROFILE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: constants.USER_PROFILE_FAIL,
            payload:
                error.response && error.response.data.errors
                    ? error.response.data.errors
                    : error.message,
        });
    }
};

export const updateProfile =
    (name, birthday, gender, degree, department) => async (dispatch) => {
        try {
            dispatch({ type: constants.USER_UPDATE_PROFILE_REQUEST });
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY_URL}/api/profile/edit`,
                {
                    name,
                    birthday,
                    gender,
                    degree,
                    department,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                }
            );
            dispatch({
                type: constants.USER_UPDATE_PROFILE_SUCCESS,
            });
            if (data["changed"]) {
                dispatch(getUserProfile());
            }
        } catch (error) {
            dispatch({
                type: constants.USER_UPDATE_PROFILE_FAIL,
                payload:
                    error.response && error.response.data.errors
                        ? error.response.data.errors
                        : error.message,
            });
        }
    };

export const verifyEmail = (id, hash, search) => async (dispatch) => {
    try {
        dispatch({ type: constants.USER_EMAIL_REQUEST });
        const { data } = await axios.get(
            `${process.env.REACT_APP_PROXY_URL}/api/email/verify/${id}/${hash + search}`
        );
        dispatch({ type: constants.USER_EMAIL_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: constants.USER_EMAIL_FAIL,
            payload:
                error.response && error.response.data.errors
                    ? error.response.data.errors
                    : error.message,
        });
    }
};

export const resendEmail = () => async (dispatch) => {
    try {
        dispatch({ type: constants.USER_EMAIL_RESEND_REQUEST });
        const { data } = await axios.post(
            `${process.env.REACT_APP_PROXY_URL}/api/email/verify/resend`
        );
        dispatch({ type: constants.USER_EMAIL_RESEND_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: constants.USER_EMAIL_RESEND_FAIL,
            payload:
                error.response && error.response.data.errors
                    ? error.response.data.errors
                    : error.message,
        });
    }
};

export const changePassword =
    (currentPassword, newPassword, confirmPassword) => async (dispatch) => {
        try {
            dispatch({ type: constants.USER_CHANGE_PASSWORD_REQUEST });
            const { data } = await axios.put(
                `${process.env.REACT_APP_PROXY_URL}/api/password/edit`,
                {
                    "current-password": currentPassword,
                    "new-password": newPassword,
                    confirmation: confirmPassword,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                }
            );
            dispatch({
                type: constants.USER_CHANGE_PASSWORD_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: constants.USER_CHANGE_PASSWORD_FAIL,
                payload:
                    error.response && error.response.data.errors
                        ? error.response.data.errors
                        : error.message,
            });
        }
    };

export const changeEmail = (email) => async (dispatch) => {
    try {
        dispatch({ type: constants.USER_CHANGE_EMAIL_REQUEST });
        const { data } = await axios.put(
            `${process.env.REACT_APP_PROXY_URL}/api/email/edit`,
            {
                email,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }
        );
        dispatch({
            type: constants.USER_CHANGE_EMAIL_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: constants.USER_CHANGE_EMAIL_FAIL,
            payload:
                error.response && error.response.data.errors
                    ? error.response.data.errors
                    : error.message,
        });
    }
};
