import styled from "styled-components";
import Input from "../components/Styles/Input.styled";
import { useEffect } from "react";
import { Button } from "../components/Styles/Button.styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { resendEmail } from "../actions/userActions";
import { useNavigate } from "react-router-dom";

const StyledMain = styled.main`
    box-sizing: border-box;
    margin: 0 auto;
    padding: 64px 0;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 64px 122px;
    gap: 30px;

    max-width: 582px;
    border-radius: 32px;
    background: #f2f2f2;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
`;

const StyledTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 57px;
    color: #000000;
`;

const StyledNote = styled.p`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 21px;

    color: #000000;
`;

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

const Mail = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;

    color: #000000;
`;

function VerifyEmailPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo);
    const { user, loading } = userInfo;
    const userRegister = useSelector((state) => state.userRegister);
    const { emailSending: registerEmailSending } = userRegister;
    const userEmailResend = useSelector((state) => state.userEmailResend);
    const { resendingEmail } = userEmailResend;
    const userChangeEmail = useSelector((state) => state.userChangeEmail);
    const { emailSending: changeEmailSending } = userChangeEmail;

    const { t } = useTranslation("VerifyEmailPage");

    const handleResendEmail = (e) => {
        e.preventDefault();
        dispatch(resendEmail());
    };

    useEffect(() => {
        if (
            (user && user.email_verified_at) ||
            (changeEmailSending === undefined &&
                registerEmailSending === undefined &&
                resendingEmail === undefined)
        ) {
            navigate("/");
        }
    }, [
        user,
        navigate,
        changeEmailSending,
        registerEmailSending,
        resendingEmail,
        loading,
    ]);

    return (
        <StyledMain>
            <Container>
                {changeEmailSending ||
                registerEmailSending ||
                resendingEmail ? (
                    <StyledTitle>{t("sending-email")}</StyledTitle>
                ) : (
                    <StyledTitle>{t("title")}</StyledTitle>
                )}
                {changeEmailSending ||
                registerEmailSending ||
                resendingEmail ? (
                    <StyledNote>{t("sending-note")}</StyledNote>
                ) : (
                    <StyledNote>{t("note")}</StyledNote>
                )}
                <Wrap>
                    <Mail>{user && user.email}</Mail>
                    <Input.Container>
                        <Button
                            type="submit"
                            value={t("resend-email")}
                            onClick={handleResendEmail}
                        />
                    </Input.Container>
                </Wrap>
            </Container>
        </StyledMain>
    );
}

export default VerifyEmailPage;
