import styled from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookSquare,
    faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import student_union from "../assets/images/student_union.svg";

const StyledFooter = styled.footer`
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    position: absolute;
`;

const Container = styled.div`
    background: #606060;
    border-radius: 16px 16px 0px 0px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 26px 123px;

    @media screen and (max-width: 1023px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        row-gap: 16px;
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        padding: 26px 0px;
    }
`;

const Legal = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0px;
    gap: 8px;

    order: 1;
`;

const StyledLink = styled(Link)`
    color: #ffffff;
    text-decoration: none;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;

    order: ${(props) => props.order};
`;

const TextField = styled.p`
    color: #ffffff;
    border-right: ${(props) => (props.divider ? "1px solid #ffffff" : "none")};
    padding-right: ${(props) => (props.divider ? "8px" : "0px")};

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
`;

const CopyRightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    gap: 4px;

    @media screen and (max-width: 1023px) {
        align-items: center;
    }
`;

const CopyRight = styled(TextField)`
    font-weight: 400;

    @media screen and (max-width: 1023px) {
        grid-column: 1/3;
    }

    @media screen and (max-width: 767px) {
        grid-column: 1;
        gap: 16px;
    }
`;

const SNS = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;

    order: 2;
`;

const IconLink = styled.a`
    width: 32px;
    height: 32px;
    text-align: center;
`;

const Icon = styled(FontAwesomeIcon)`
    color: #ffffff;
`;

const Image = styled.img`
    height: 32px;
    width: 28px;
`;

const StyledAnchor = styled.a`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-decoration: none;
    color: #ffffff;
`;

function SimpleFooter() {
    const { t } = useTranslation("Footer");

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <StyledFooter>
            <Container>
                <CopyRightContainer>
                    <CopyRight>
                        Copyright © 2022 UCLKS. All rights reserved.
                    </CopyRight>
                    <StyledAnchor href="http://www.freepik.com">
                        {t("simple.freepik")}
                    </StyledAnchor>
                </CopyRightContainer>
                <Legal>
                    <StyledLink to="/terms" order="0" onClick={scrollToTop}>
                        <TextField divider>{t("simple.terms")}</TextField>
                    </StyledLink>
                    <StyledLink to="/privacy" order="1" onClick={scrollToTop}>
                        <TextField>{t("simple.privacy")}</TextField>
                    </StyledLink>
                    {/* <StyledLink to="/sitemap" order="2">
                        <TextField>{t("simple.sitemap")}</TextField>
                    </StyledLink> */}
                </Legal>
                <SNS>
                    <IconLink href="https://studentsunionucl.org/clubs-societies/korean-society">
                        <Image src={student_union} alt="student union icon" />
                    </IconLink>
                    <IconLink href="https://www.facebook.com/groups/uclks">
                        <Icon icon={faFacebookSquare} size="2xl" />
                    </IconLink>
                    <IconLink href="https://instagram.com/uclksoc">
                        <Icon icon={faInstagram} size="2xl" />
                    </IconLink>
                </SNS>
            </Container>
        </StyledFooter>
    );
}

export default SimpleFooter;
