import styled from "styled-components";

const StyledMain = styled.main`
    box-sizing: border-box;
    margin: 0 auto;
    padding: 64px 0;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    box-sizing: border-box;
    padding: 0 3px;
    width: 1200px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
`;

const StyledTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 70px;
    line-height: 84px;

    color: #000000;
`;

const StyledSubTitle = styled.h3`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    color: #000000;
`;

const Articles = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
`;

const Article = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    align-self: stretch;
    gap: 12px;
`;

const ArticleTitle = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;

    color: #000000;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    gap: 4px;
`;

const StyledParagraph = styled.p`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
`;

const StyledWriter = styled.h4`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    align-self: stretch;
`;

const Row = styled.ul`
    display: flex;
    flex-direction: column;
    list-style-type: ${({ type }) => (type ? type : "decimal")};
    padding-left: 30px;
    gap: 2px;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
`;
const Col = styled.li`
    display: list-item;
`;

function ConstitutionPage() {
    return (
        <StyledMain>
            <Container>
                <TitleContainer>
                    <StyledTitle>회칙</StyledTitle>
                    <StyledSubTitle>마지막 업데이트: 2020-08-23</StyledSubTitle>
                </TitleContainer>

                <Articles>
                    <Article>
                        <ArticleTitle>제1장 총칙</ArticleTitle>
                        <Content>
                            <StyledSubTitle>제1조 (명칭)</StyledSubTitle>
                            <StyledParagraph>
                                본회의 명칭은 ‘UCL 한인학생회’ 이다.
                            </StyledParagraph>
                        </Content>
                        <Content>
                            <StyledSubTitle>제2조 (소속)</StyledSubTitle>
                            <Row>
                                <Col>
                                    본회는 UCL Students’ Union 에 소속 되어있는
                                    단체이다.
                                </Col>
                                <Col>
                                    본회는 UCL Students’ Union 이 제정한 방침 및
                                    지침에 따른다.
                                </Col>
                            </Row>
                        </Content>
                        <Content>
                            <StyledSubTitle>제3조 (주소)</StyledSubTitle>
                            <StyledParagraph>
                                본회의 주소는 UCL Students' Union으로 한다.
                            </StyledParagraph>
                        </Content>
                        <Content>
                            <StyledSubTitle>제4조 (목적)</StyledSubTitle>
                            <StyledParagraph>
                                본회는 회원 상호간의 친목과 우의를 돈독히 하고
                                신뢰와 연대성을 기초한 전체회원의 공동 이익을
                                실현함을 그 목적으로 한다.
                            </StyledParagraph>
                        </Content>
                        <Content>
                            <StyledSubTitle>제5조 (회원)</StyledSubTitle>
                            <StyledParagraph>
                                본회의 회원은 UCL에 재학중인 모든 한인 학생으로
                                한다.
                            </StyledParagraph>
                        </Content>
                        <Content>
                            <StyledSubTitle>제6조 (회원의 권리)</StyledSubTitle>
                            <Row>
                                <Col>
                                    본회의 회원은 선거권과 피선거권을 가지며
                                    본회의 활동에 참여할 권리가 있다.
                                </Col>
                                <Col>
                                    본회의 회원은 본회의 운영전반에 대해 알
                                    권리를 가진다.
                                </Col>
                                <Col>
                                    본회의 회원 중 휴학생은 다음 각 호에 명시된
                                    회원으로서의 권리를 제한한다.
                                    <Row type="lower-roman">
                                        <Col>선거권 및 피선거권</Col>
                                        <Col>
                                            각종 투표권 및 의사결정과정에 대한
                                            참가권
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Content>
                        <Content>
                            <StyledSubTitle>제7조 (회원의 의무)</StyledSubTitle>
                            <Row>
                                <Col>
                                    본회의 회원은 회칙을 준수할 의무가 있다.
                                </Col>
                                <Col>
                                    본회의 회원은 UCL Students’ Union이 운영하는
                                    멤버십을 통해 회비를 납부하여야 할 의무가
                                    있다.
                                </Col>
                                <Col>
                                    본회의 회원은 본회의 구성원으로서 본회가
                                    민주적으로 결정한 사항을 준수하고 본회를
                                    수호할 의무가 있다.
                                </Col>
                            </Row>
                        </Content>
                    </Article>

                    <Article>
                        <ArticleTitle>제2장 조직</ArticleTitle>
                        <Content>
                            <StyledSubTitle>제8조 (지위)</StyledSubTitle>
                            <StyledParagraph>
                                집행부는 본회의 최고 집행기구이다.
                            </StyledParagraph>
                        </Content>
                        <Content>
                            <StyledSubTitle>제9조 (구성)</StyledSubTitle>
                            <Row>
                                <Col>집행부의 체계와 구성은 회장이 한다.</Col>
                                <Col>
                                    집행부는 회장, 부회장, 총무, 서기, 자문,
                                    기획부, 대외부, 아카데믹부로 구성한다.
                                </Col>
                            </Row>
                        </Content>
                        <Content>
                            <StyledSubTitle>제10조 (업무)</StyledSubTitle>
                            <Row>
                                <Col>
                                    회장은 본회를 대표하여 본회의 활동전반을
                                    책임지고 이를 수행한다.
                                </Col>
                                <Col>
                                    부회장은 학생회장을 보좌하여 집행부 총괄
                                    관리 및 지원한다.
                                </Col>
                                <Col>
                                    총무는 본회의 수입과 지출 등 재정사무 일체를
                                    담당한다.
                                </Col>
                                <Col>
                                    서기는 집행부 회의록 작성 및 각종 문서를
                                    보관, 관리한다.
                                </Col>
                                <Col>
                                    자문은 본회의 활동의 전반적인 조언과
                                    활동부서를 관리 한다.
                                </Col>
                                <Col>
                                    기획부는 본회의 모든 활동을 기획, 실행한다.
                                </Col>
                                <Col>
                                    대외부는 총회 및 기타 임원회의 의결사항 및
                                    각종 활동들에 관한 사항을 홍보한다.
                                </Col>
                                <Col>
                                    아카데믹부는 동문회 및 기타 아카데믹행사를
                                    기획하고 동문들간의 교류와 소통을 지원한다.
                                </Col>
                            </Row>
                        </Content>
                        <Content>
                            <StyledSubTitle>제11조 (회의)</StyledSubTitle>
                            <Row>
                                <Col>
                                    정기 임원 회의는 주 1회로 하고, 회장이 이를
                                    소집한다.
                                </Col>
                                <Col>
                                    모든 임원들은 회의에 참석해야 할 의무가
                                    있으며 임원 회의에서 수시로 각 부서의 현황을
                                    보고하여야한다.
                                </Col>
                            </Row>
                        </Content>
                    </Article>

                    <Article>
                        <ArticleTitle>제3장 학생회 활동부서</ArticleTitle>
                        <Content>
                            <StyledSubTitle>제12조 (구성)</StyledSubTitle>
                            <StyledParagraph>
                                학생회 활동부서는 축구부, 농구부, 밴드부,
                                댄스부로 이루어져있다.
                            </StyledParagraph>
                        </Content>
                        <Content>
                            <StyledSubTitle>제13조 (활동 부장)</StyledSubTitle>
                            <Row>
                                <Col>
                                    활동 부장은 각 부서의 재량에 따라 선출한다.
                                </Col>
                                <Col>
                                    활동 부장은 본회의 발전과 부원 상호 간의
                                    친목 도모를 위해 일할 의무가 있다.
                                </Col>
                                <Col>
                                    활동 부장은 각 부서의 필요 및 건의 사항을
                                    자문에게 보고한다.
                                </Col>
                                <Col>
                                    활동 부장의 임기는 1년으로 하고 연임은
                                    불가하다. 단, 신설된 활동부서에 한해서 최대
                                    1회 연임이 가능하다.
                                </Col>
                            </Row>
                        </Content>
                        <Content>
                            <StyledSubTitle>제14조 (지출)</StyledSubTitle>
                            <Row>
                                <Col>
                                    활동 부장은 런던 신입생 설명회 이후 21일
                                    내에 지정된 양식을 통해 계획안 및 예산안과
                                    함께 각 부서 인원보고를 회장에게 제출한다.
                                    이후에 추가되는 인원에 대해서는 상시
                                    보고한다.
                                </Col>
                                <Col>
                                    활동 부장은 각 부서의 공동지출건에 대해서
                                    영수증을 보관하여야 한다.
                                </Col>
                                <Col>
                                    활동 부장은 매학기 말에 지출 내역을 영수증과
                                    함께 회장에게 보고하여야한다.
                                </Col>
                            </Row>
                        </Content>
                        <Content>
                            <StyledSubTitle>제15조 (활동 부원)</StyledSubTitle>
                            <Row>
                                <Col>
                                    모든 활동 부원은 UCL Students’ Union이
                                    운영하는 멤버십을 통해 회비를 납부하여야 할
                                    의무가 있다.
                                </Col>
                                <Col>
                                    활동 부원은 회비를 납부 하지 않을 시에는
                                    활동 부서 참여가 불가하다.
                                </Col>
                                <Col>
                                    활동 부원은 각 부서 규율을 준수할 의무가
                                    있다.
                                </Col>
                                <Col>
                                    활동 부장을 포함한 모든 활동 부원은 제 13조,
                                    14조 및 15조에 해당하는 회칙을 불이행 할시에
                                    임원회의를 통해 활동부서 해산 여부를
                                    검토한다.
                                </Col>
                            </Row>
                        </Content>
                    </Article>

                    <Article>
                        <ArticleTitle>제4장 총회</ArticleTitle>
                        <Content>
                            <StyledSubTitle>
                                제16조 (총회의 권한 사항)
                            </StyledSubTitle>
                            <Row>
                                <Col>
                                    정기총회는 연 1회이며, 회장이 이를 소집한다.
                                </Col>
                                <Col>
                                    임시총회는 재적회원 15명 이상의 연서에 의한
                                    요청이 있거나 회장이 필요하다고 인정 할 때에
                                    소집한다.
                                </Col>
                                <Col>
                                    총회의 소집은 1주 전에 회의의 목적을 기재한
                                    통지를 각 회원앞으로 발송함으로써 한다.
                                </Col>
                            </Row>
                        </Content>
                        <Content>
                            <StyledSubTitle>
                                제17조 (총회의 권한 사항)
                            </StyledSubTitle>
                            <Row>
                                <Col>정기총회에서 회장 및 총무 선출</Col>
                                <Col>정기총회에서의 업무 및 결산보고 청취</Col>
                                <Col>회칙개정에 관한 결의</Col>
                                <Col>기타 집행부에서 부의한 안건의 토의</Col>
                            </Row>
                        </Content>
                        <Content>
                            <StyledSubTitle>제18조 (의결권)</StyledSubTitle>
                            <Row>
                                <Col>정회원의 의결권은 평등으로 한다.</Col>
                                <Col>정회원은 의결권을 행사할 수 있다.</Col>
                            </Row>
                        </Content>
                        <Content>
                            <StyledSubTitle>
                                제19조 (총회의 의결과 안건처리)
                            </StyledSubTitle>
                            <StyledParagraph>
                                총회의 결의는 인터넷을 통한 투표자 과반수의
                                찬성으로 한다.
                            </StyledParagraph>
                        </Content>
                    </Article>

                    <Article>
                        <ArticleTitle>제5장 학생회장 및 총무</ArticleTitle>
                        <Content>
                            <StyledSubTitle>제20조 (임기)</StyledSubTitle>
                            <StyledParagraph>
                                회장과 총무의 임기는 1년으로 하고, 당선 익일부터
                                그 임기가 시작된다.
                            </StyledParagraph>
                        </Content>
                        <Content>
                            <StyledSubTitle>제21조 (선출)</StyledSubTitle>
                            <Row>
                                <Col>
                                    회장 및 총무에 대한 피선거권은 본 회칙 제5조
                                    및 제7조에 해당하고 본회가 주최한 활동에 2회
                                    이상 참여한 학생으로 한다.
                                </Col>
                                <Col>
                                    회장 및 총무는 제18조 제1항에 의거하여
                                    정기총회에서 선출한다.
                                </Col>
                                <Col>
                                    회장후보는 지원서를 통한 입후보 등록을
                                    해야할 의무가 있다.
                                </Col>
                                <Col>
                                    회장 및 총무의 선출은 인터넷을 통한 무기명
                                    비밀투표에 의하여 결정하되 참석회원 과반수의
                                    득표를 요한다. 단, 과반수의 득표자가 없을
                                    때에는 1회에 한하여 재투표를 실시하여 그 중
                                    최다득표자로 결정한다.
                                </Col>
                            </Row>
                        </Content>
                    </Article>

                    <Article>
                        <ArticleTitle>제6장 재정</ArticleTitle>
                        <Content>
                            <StyledSubTitle>제22조 (회계연도)</StyledSubTitle>
                            <StyledParagraph>
                                본회의 사업 및 재정의 회계연도는 매년 정기총회의
                                종료일부터 익년 정기 총회의 전일까지로 한다.
                            </StyledParagraph>
                        </Content>
                        <Content>
                            <StyledSubTitle>제23조 (수입)</StyledSubTitle>
                            <StyledParagraph>
                                본회의 재정은 회원들의 회비 및 기타 사업의
                                수익금으로 충당한다.
                            </StyledParagraph>
                        </Content>
                        <Content>
                            <StyledSubTitle>제24조 (지출)</StyledSubTitle>
                            <Row>
                                <Col>
                                    본회의 재정실무는 총무가 주관하되 회장의
                                    결재를 요구한다.
                                </Col>
                                <Col>
                                    예상외 사업으로서의 긴급을 요할 경우에는
                                    부회장이 회장의 결재를 받아 시행하되 사후
                                    회장단의 승인을 얻어야 한다.
                                </Col>
                            </Row>
                        </Content>
                        <Content>
                            <StyledSubTitle>제25조 (보고)</StyledSubTitle>
                            <StyledParagraph>
                                총무는 다음과 같이 재정상항을 보고하여야 한다.
                            </StyledParagraph>
                            <Row>
                                <Col>
                                    임원 회의에서 수시로 구두보고하여야 한다.
                                </Col>
                                <Col>
                                    정기총회시에는 결산 보고서를 작성하여
                                    보고하여야 한다.
                                </Col>
                            </Row>
                        </Content>
                    </Article>

                    <Article>
                        <ArticleTitle>제7장 회칙 제정 및 개정</ArticleTitle>
                        <Content>
                            <StyledSubTitle>제26조 (개정조건)</StyledSubTitle>
                            <StyledParagraph>
                                본 회칙 개정에 관하여 회장단의 건의 혹은 총회
                                재적 10명 이상의 연서에 의한 요청이 있을 경우,
                                회장은 1개월 이내에 회칙개정을 위한 임시총회를
                                소집하여야 한다. 단, 위 기간안에 정기총회가
                                소집되어야 할 경우에는 정기총회에서 이를
                                다루도록 한다.
                            </StyledParagraph>
                        </Content>
                    </Article>

                    <Article>
                        <ArticleTitle>제8장 부칙</ArticleTitle>
                        <Content>
                            <StyledSubTitle>제27조 (적용)</StyledSubTitle>
                            <StyledParagraph>
                                본 약관은 한국어를 정본으로 하며, 영국의 법령이
                                적용됩니다.
                            </StyledParagraph>
                        </Content>
                        <Content>
                            <StyledSubTitle>제28조 (발효)</StyledSubTitle>
                            <StyledParagraph>
                                이 약관은 2022년 8월 23일부터 시행됩니다.
                                <br />
                                <br />
                                UCLKS 이용약관의 관련하여 궁금하신 사항이
                                있으시면 uclks.committee@gmail.com로 문의 주시기
                                바랍니다.
                            </StyledParagraph>
                        </Content>
                    </Article>
                </Articles>

                <StyledWriter>22/23 UCL 한인 학생회장 이서우</StyledWriter>
            </Container>
        </StyledMain>
    );
}

export default ConstitutionPage;
