import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Nav = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
`;

const StyledNav = styled(NavLink)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    text-decoration: none;

    -webkit-tap-highlight-color: transparent;
`;

function shapeType(shape) {
    switch (shape) {
        case "left":
            return "8px 16px 8px 24px";
        case "middle":
            return "8px 16px";
        case "right":
            return "8px 24px 8px 16px";
        default:
            return "8px 16px";
    }
}

const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    padding: ${({ shape }) => shapeType(shape)};

    height: 84px;

    background: #606060;
`;

const StyledText = styled.p`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: #000000;

    display: flex;
    align-items: center;
    text-align: center;

    position: relative;
    z-index: 3;
`;

const Decoration = styled.div`
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    height: 16px;
    float: left;
    clear: both;
    position: relative;
`;

const Retangle = styled.span`
    background: #606060;
    border-radius: 0px 0px 8px 8px;
    height: 16px;
    flex-grow: 1;
    display: block;
`;

const Block = styled.span`
    width: 8px;
    height: 8px;
    background: #606060;

    position: ${({ enabled }) => (enabled ? "absolute" : "static")};
    left: ${({ position }) => (position === "left" ? "-8px" : "auto")};
    right: ${({ position }) => (position === "right" ? "-8px" : "auto")};
    top: 0px;
    z-index: -1;

    &::before {
        content: "";
        width: 8px;
        height: 8px;
        background: ${({ colour }) => (colour ? colour : "#ffffff")};
        position: absolute;
        border-radius: ${({ position }) =>
            position === "left" ? "0px 8px 0px 0px" : "8px 0px 0px 0px"};
        z-index: -2;
    }
`;

const GNBElement = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    ${StyledNav} {
        &.active {
            ${StyledText} {
                color: #ffffff;
                @keyframes moveDown {
                    from {
                        bottom: 0px;
                    }
                    to {
                        bottom: -16px;
                    }
                }
                animation: 0.2s ease-in-out 1 forwards moveDown;
            }

            ${Decoration} {
                display: flex;
                @keyframes moveIn {
                    from {
                        bottom: 16px;
                    }
                    to {
                        bottom: 0px;
                    }
                }
                animation: 0.2s ease-in-out 1 forwards moveIn;
            }
        }
    }
`;

function GNB() {
    const [t] = useTranslation("Header");
    const path = useLocation().pathname;
    return (
        <Nav>
            <GNBElement>
                <StyledNav to="/">
                    <TextBox shape="left">
                        <StyledText>{t("nav.home")}</StyledText>
                    </TextBox>
                    <Decoration>
                        <Block
                            position="left"
                            colour={path === "/introduce" && "#92B4EC"}
                        />
                        <Retangle />
                        <Block
                            position="right"
                            enabled
                            colour={path === "/introduce" && "#92B4EC"}
                        />
                    </Decoration>
                </StyledNav>
            </GNBElement>

            <GNBElement>
                <StyledNav to="/introduce">
                    <TextBox shape="middle">
                        <StyledText>UCLKS</StyledText>
                    </TextBox>
                    <Decoration>
                        <Block
                            position="left"
                            enabled
                            colour={path === "/introduce" && "#92B4EC"}
                        />
                        <Retangle />
                        <Block
                            position="right"
                            enabled
                            colour={path === "/introduce" && "#92B4EC"}
                        />
                    </Decoration>
                </StyledNav>
            </GNBElement>

            {/* <GNBElement>
                <StyledNav to="/events">
                    <TextBox shape="middle">
                        <StyledText>{t("nav.event")}</StyledText>
                    </TextBox>
                    <Decoration>
                        <Block
                            position="left"
                            enabled
                            colour={path === "/introduce" && "#92B4EC"}
                        />
                        <Retangle />
                        <Block
                            position="right"
                            enabled
                            colour={path === "/introduce" && "#92B4EC"}
                        />
                    </Decoration>
                </StyledNav>
            </GNBElement> */}

            {/* <GNBElement>
                <StyledNav to="/gallery">
                    <TextBox shape="middle">
                        <StyledText>{t("nav.gallery")}</StyledText>
                    </TextBox>
                    <Decoration>
                        <Block
                            position="left"
                            enabled
                            colour={path === "/introduce" && "#92B4EC"}
                        />
                        <Retangle />
                        <Block
                            position="right"
                            enabled
                            colour={path === "/introduce" && "#92B4EC"}
                        />
                    </Decoration>
                </StyledNav>
            </GNBElement> */}

            <GNBElement>
                <StyledNav to="/module-feedback">
                    <TextBox shape="right">
                        <StyledText>{t("nav.module-feedback")}</StyledText>
                    </TextBox>
                    <Decoration>
                        <Block
                            position="left"
                            enabled
                            colour={path === "/introduce" && "#92B4EC"}
                        />
                        <Retangle />
                        <Block
                            position="right"
                            colour={path === "/introduce" && "#92B4EC"}
                        />
                    </Decoration>
                </StyledNav>
            </GNBElement>
        </Nav>
    );
}

export default GNB;
