import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getDepartments } from "../actions/departmentActions";
import {
    faSearch,
    faXmark,
    faAngleDown,
    faArrowDownAZ,
    faArrowUpAZ,
} from "@fortawesome/free-solid-svg-icons";
import SearchType from "../components/Styles/SearchType";
import { Link } from "react-router-dom";
import { DEPARTMENTS_RESET } from "../constants/departmentConstants";
import { useTranslation } from "react-i18next";
import Info from "../components/Info";

const StyledMain = styled.main`
    box-sizing: border-box;
    margin: 0 auto;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;

    @media screen and (max-width: 768px) {
        margin: 30px;
        padding: 0;
        gap: 30px;
    }
`;

const TitleContainer = styled.div`
    display: grid;

    grid-template-columns: 1fr 400px;
    gap: 30px;
    width: 1194px;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        width: 315px;
    }
`;

const Title = styled.h1`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 16px;
    font-family: "Pretendard Variable";
    font-style: normal;

    font-weight: 900;
    font-size: 70px;
    line-height: 84px;

    color: #ffffff;

    background: #92b4ec;
    border-radius: 16px;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 32px;

        align-self: stretch;

        font-size: 48px;
        line-height: 57px;
    }
`;

const Comment = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 32px;
    gap: 32px;

    box-sizing: border-box;
    background: #92b4ec;
    border-radius: 16px;

    @media screen and (max-width: 768px) {
        padding: 16px 32px;
        gap: 16px;

        align-self: stretch;
    }
`;

const CommentTitle = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 57px;
    display: flex;
    align-items: flex-end;

    color: #ffffff;

    @media screen and (max-width: 768px) {
        font-size: 32px;
        line-height: 38px;
    }
`;

const CommentContent = styled.p`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    display: flex;

    color: #ffffff;

    @media screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 21px;
    }
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    @media screen and (max-width: 768px) {
        gap: 15px;
    }
`;

const SearchContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 32px;
    gap: 32px;

    width: 1194px;

    border: 3px solid #606060;
    border-radius: 32px;

    @media screen and (max-width: 768px) {
        padding: 8px 16px;
        gap: 16px;

        width: auto;

        border: 2px solid #606060;
        border-radius: 16px;

        align-self: stretch;
    }
`;

const SearchForm = styled.form`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;

    flex-grow: 1;

    &::after {
        content: "";
        width: 3px;
        align-self: stretch;
        background: #606060;
        border-radius: 2px;
        margin-left: 16px;

        @media screen and (max-width: 768px) {
            width: 2px;
        }
    }

    label {
        display: flex;
        justify-content: center;
    }
`;

const Search = styled.input`
    border: 0;
    outline: none;
    padding: 0;
    width: 0;
    flex-grow: 1;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: #000000;

    &::placeholder {
        color: #606060;
    }

    @media screen and (max-width: 768px) {
        font-weight: 900;
        font-size: 18px;
        line-height: 21px;
    }
`;

const Icon = styled(FontAwesomeIcon)`
    width: 24px;
    height: 24px;
    color: #606060;

    @media screen and (max-width: 768px) {
        width: 16px;
        height: 16px;
    }
`;

const Cards = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;

    width: 1194px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 15px;
        width: 315px;
    }
`;

const Card = styled(Link)`
    box-sizing: border-box;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 32px;

    background: #f2f2f2;
    border-radius: 16px;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;

    color: #000000;

    text-decoration: none;

    @media screen and (max-width: 768px) {
        padding: 16px;

        width: auto;

        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
    }
`;

function ModuleFeedbackPage() {
    const dispatch = useDispatch();
    const departments = useSelector((state) => state.departments);
    const { departments: departmentList } = departments;
    const { t } = useTranslation("ModuleFeedbackPage");

    const [keyword, setKeyword] = useState("");
    const [descending, setDescending] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(getDepartments(keyword, descending));
    };

    const handleOptionClicked = (option) => {
        setDescending(option);
        setIsOpen(false);
    };

    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        dispatch(getDepartments(keyword, descending));
        return () => {
            dispatch({ type: DEPARTMENTS_RESET });
        };
    }, [descending, dispatch]);

    return (
        <StyledMain>
            <TitleContainer>
                <Title>{t("title")}</Title>
                <Comment>
                    <CommentTitle>{t("comment.title")}</CommentTitle>
                    <CommentContent>{t("comment.content")}</CommentContent>
                </Comment>
            </TitleContainer>

            <MainContainer>
                <SearchContainer onSubmit={handleSubmit}>
                    <SearchForm>
                        <label htmlFor="keyword">
                            <Icon icon={faSearch} size="2xl" />
                        </label>
                        <Search
                            type="text"
                            id="keyword"
                            placeholder={t("search")}
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                        <Icon
                            onClick={(e) => setKeyword("")}
                            icon={faXmark}
                            size="2xl"
                        />
                    </SearchForm>

                    <SearchType.Container>
                        <SearchType.CurrentOption
                            className={isOpen && "clicked"}
                            onClick={(e) => setIsOpen((current) => !current)}
                        >
                            {descending ? (
                                <SearchType.Icon icon={faArrowUpAZ} />
                            ) : (
                                <SearchType.Icon icon={faArrowDownAZ} />
                            )}
                            <SearchType.Icon
                                icon={faAngleDown}
                                size="2xl"
                                className="flip"
                            />
                        </SearchType.CurrentOption>
                        <SearchType.Options className={isOpen && "clicked"}>
                            <SearchType.Icon
                                className="option"
                                onClick={(e) => handleOptionClicked(false)}
                                icon={faArrowDownAZ}
                            />
                            <SearchType.Divider />
                            <SearchType.Icon
                                className="option"
                                onClick={(e) => handleOptionClicked(true)}
                                icon={faArrowUpAZ}
                            />
                        </SearchType.Options>
                    </SearchType.Container>
                </SearchContainer>
                <Cards>
                    {departmentList?.map((department) => {
                        return (
                            <Card
                                to={`departments/${department.id}/modules`}
                                key={department.id}
                                onClick={scrollToTop}
                            >
                                {department.name}
                            </Card>
                        );
                    })}
                </Cards>
            </MainContainer>
            <Info />
        </StyledMain>
    );
}

export default ModuleFeedbackPage;
