import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const Options = styled.div`
	display: none;
`;

const CurrentLanguage = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	border: none;

	width: 276px;
	padding: 8px 16px;

	background: #606060;
	border-radius: 16px;
`;

const Icon = styled(FontAwesomeIcon)`
	width: 32px;
	height: 32px;
	text-align: center;
	color: #ffffff;
	position: relative;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;

	&:hover {
		${Options} {
			display: block;
		}
		${CurrentLanguage} {
			${Icon} {
				&.flip {
					@keyframes flip {
						from {
							top: 10px;
							opacity: 0%;
						}
						to {
							top: 0px;
							opacity: 100%;
						}
					}
					animation-duration: 0.2s;
					animation-timing-function: ease-in-out;
					animation-delay: 0s;
					animation-iteration-count: 1;
					animation-direction: normal;
					animation-fill-mode: forwards;
					animation-play-state: running;
					animation-name: flip;
					transform: scaleY(-1);
				}
			}
		}
	}
`;

const Current = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	order: 0;

	h2 {
        font-family: "Pretendard Variable";
		font-style: normal;
		font-weight: 900;
		font-size: 24px;
		line-height: 29px;
		color: #ffffff;
	}
`;

const LanguageType = styled.button`
	font-family: "Pretendard Variable";
	font-style: normal;
	font-weight: 900;
	font-size: 24px;
	line-height: 29px;
	color: #ffffff;

	background-color: rgba(0, 0, 0, 0);
	border: none;
	padding: 0px;
`;

const Row = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 276px;

	box-sizing: border-box;
	padding: 8px;
	gap: 8px;

	background: #606060;
	border-radius: 16px;
`;

const Col = styled.li`
	border-bottom: ${(props) => (props.divider ? "1px solid #ffffff" : "none")};
	padding-bottom: ${(props) => (props.divider ? "8px" : "0px")};

	text-align: center;
	width: 100%;

	&:hover {
		button {
			color: #92b4ec;
			cursor: pointer;
		}
	}
`;

function ChangeLanguage() {
	const { t, i18n } = useTranslation("Footer");
	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
	};

	return (
		<Container>
			<CurrentLanguage>
				<Current>
					<Icon icon={faGlobe} size="2xl" />
					<h2>{t("full.current_language")}</h2>
				</Current>
				<Icon icon={faAngleDown} size="2xl" className="flip" />
			</CurrentLanguage>
			<Options>
				<Row>
					<Col className="center" divider>
						<LanguageType onClick={() => changeLanguage("ko")}>
							한국어
						</LanguageType>
					</Col>
					<Col className="center">
						<LanguageType onClick={() => changeLanguage("en")}>
							English
						</LanguageType>
					</Col>
				</Row>
			</Options>
		</Container>
	);
}

export default ChangeLanguage;
