import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    flex-grow: 1;

    h4 {
        font-family: "Pretendard Variable";
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 19px;

        color: #000000;
    }
`;

const TextField = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 16px;
    gap: 16px;

    width: 244px;
    min-height: 178px;

    background: #92b4ec;
    border-radius: 16px;

    align-self: stretch;
`;

const Text = styled.p`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;

    color: #ffffff;

    align-self: stretch;
    width: 212px;
    min-height: 95px;
    text-align: left;

    &.hidden {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
`;

const Icon = styled(FontAwesomeIcon)`
    width: ${({ dimension }) => (dimension ? dimension : "32px")};
    height: ${({ dimension }) => (dimension ? dimension : "32px")};
    color: ${({ colour }) => (colour ? colour : "#ffffff")};

    position: relative;
`;

const ShowMore = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 0px;
    gap: 8px;

    background: #ffffff;
    border-radius: 8px;

    align-self: stretch;

    border: none;

    h5 {
        font-family: "Pretendard Variable";
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 19px;

        color: #000000;
    }

    &:active {
        background: transparent;
        padding: 5px 16px;
        border: 3px solid #ffffff;

        h5 {
            color: #ffffff;
        }

        ${Icon} {
            color: #ffffff;
        }
    }
    &.clicked {
        ${Icon} {
            &.flip {
                @keyframes flip {
                    from {
                        top: 10px;
                        opacity: 0%;
                    }
                    to {
                        top: 0px;
                        opacity: 100%;
                    }
                }
                animation-duration: 0.2s;
                animation-timing-function: ease-in-out;
                animation-delay: 0s;
                animation-iteration-count: 1;
                animation-direction: normal;
                animation-fill-mode: forwards;
                animation-play-state: running;
                animation-name: flip;
                transform: scaleY(-1);
            }
        }
    }
`;

function CommentBox({ text, type }) {
    const [isOpen, setIsOpen] = useState(false);

    const [isClammped, setIsClammped] = useState(false);
    const textBox = useRef();

    const { t } = useTranslation("CommentBox");

    useEffect(() => {
        setIsClammped(
            textBox.current.scrollHeight > textBox.current.clientHeight
        );
    }, [setIsClammped]);

    return (
        <TextContainer>
            <h4>{type === "tips" ? t("tips") : t("comments")}</h4>
            <TextField>
                <Text className={isOpen ? "" : "hidden"} ref={textBox}>
                    {text === null ? t("no-text") : text}
                </Text>
                {isClammped && (
                    <ShowMore
                        className={isOpen ? "clicked" : ""}
                        onClick={(e) => setIsOpen((current) => !current)}
                    >
                        <h5>{isOpen ? t("close") : t("show-more")}</h5>
                        <Icon
                            icon={faAngleDown}
                            dimension="16px"
                            colour="#000000"
                            className="flip"
                        />
                    </ShowMore>
                )}
            </TextField>
        </TextContainer>
    );
}

export default CommentBox;
