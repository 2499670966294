import * as constants from "../constants/moduleConstants";

export const moduleReducer = (state = {}, action) => {
    switch (action.type) {
        case constants.MODULE_REQUEST:
            return { loading: true };
        case constants.MODULE_SUCCESS:
            return { loading: false, module: action.payload };
        case constants.MODULE_FAIL:
            return { loading: false, error: action.payload };
        case constants.MODULE_RESET:
            return {};
        default:
            return state;
    }
};

export const moduleAverageReducer = (state = {}, action) => {
    switch (action.type) {
        case constants.MODULE_AVERAGE_REQUEST:
            return { loading: true };
        case constants.MODULE_AVERAGE_SUCCESS:
            return { loading: false, average: action.payload };
        case constants.MODULE_AVERAGE_FAIL:
            return { loading: false, error: action.payload };
        case constants.MODULE_AVERAGE_RESET:
            return {};
        default:
            return state;
    }
};
