import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/userActions";

const Dropdown = styled.div`
    display: none;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;

    position: relative;

    overflow: hidden;

    &::before {
        content: "";
        background: #606060;
        width: 8px;
        height: 8px;
        z-index: 0;
    }
    &::after {
        content: "";
        background: #606060;
        width: 8px;
        height: 8px;
        z-index: 0;
    }
`;

const Icon = styled(FontAwesomeIcon)`
    color: #ffffff;
    position: relative;
`;

const Logo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 84px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;

    &:hover {
        ${Dropdown} {
            display: flex;
        }
        ${Logo} {
            ${Icon} {
                @keyframes move {
                    from {
                        right: -40px;
                    }
                    to {
                        right: 0px;
                    }
                }
                animation: 0.2s ease-in-out 1 move;
            }
        }
    }
`;

const Shape = styled.span`
    width: 8px;
    height: 8px;
    background: ${({ colour }) => (colour ? colour : "#ffffff")};
    border-radius: ${({ position }) =>
        position === "left" ? "0px 8px 0px 0px" : "8px 0px 0px 0px"};

    position: absolute;
    left: ${({ position }) => (position === "left" ? "0px" : "none")};
    right: ${({ position }) => (position === "right" ? "0px" : "none")};
    top: 0px;
    z-index: 1;
`;

const Options = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: #606060;
    border-radius: 0px 0px 8px 8px;

    padding: 8px 16px;
    gap: 8px;
`;

const Option = styled.li`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;

    text-align: center;
    align-self: stretch;

    display: flex;
    flex-direction: column;
    align-items: center;

    color: #ffffff;

    box-sizing: border-box;
    padding-bottom: ${({ divider }) => (divider ? "8px" : "0px")};

    border-bottom: ${({ divider }) => (divider ? "1px solid #ffffff" : "none")};

    &.clickable:hover {
        cursor: pointer;
    }
`;

const StyledLink = styled(Link)`
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;

    text-decoration: none;

    color: #ffffff;
`;

function Logout() {
    const path = useLocation().pathname;
    const dispatch = useDispatch();
    const [t] = useTranslation("Header");
    const handleLogout = () => {
        dispatch(logout());
    };
    const userProfile = useSelector((state) => state.userProfile);
    const { user } = userProfile;
    return (
        <Container>
            <Logo>
                <Icon icon={faCircleUser} size="2xl" />
            </Logo>
            <Dropdown>
                <Shape
                    position="left"
                    colour={path === "/introduce" && "#92B4EC"}
                />
                <Options>
                    <Option divider>{user && user.name}</Option>
                    <Option>
                        <StyledLink to="/profile">
                            {t("logout.profile")}
                        </StyledLink>
                    </Option>
                    <Option className="clickable" onClick={handleLogout}>
                        {t("logout.logout")}
                    </Option>
                </Options>
                <Shape
                    position="right"
                    colour={path === "/introduce" && "#92B4EC"}
                />
            </Dropdown>
        </Container>
    );
}

export default Logout;
