import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import design from "../assets/images/home_design.svg";

const StyledMain = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 64px 0;
`;

const BannerSection = styled.section`
    height: 336px;
    width: 100%;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    position: absolute;
    left: calc(50% - 336px / 2 + 313px);
    top: 188px;
`;

const StyledTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 96px;
    line-height: 115px;

    color: #000000;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 16px;

    background: #92b4ec;
    border-radius: 16px;
`;

const StyledSubTitle = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;

    color: #606060;
`;

const Image = styled.img`
    width: 596px;
    height: 596px;
    position: absolute;
    left: calc(50% - 596px / 2 - 321px);
    top: 71px;
`;

const AnnoucementSection = styled.section`
    background-image: url(${design});
    background-repeat: repeat-x;
    background-position: center;
    background-size: 1280px 623px;
    box-sizing: border-box;
    padding: 64px 0px;
    height: 623px;
    width: 100%;
    z-index: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 30px;
`;

const ComingEvent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    align-self: stretch;
`;

const ComingSoonTitle = styled.h3`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 76px;

    color: #ffffff;
`;

const NextEvent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
`;

const NextEventTitle = styled.h4`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;

    color: #ffffff;
`;

const FindOutMore = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`;

const FindOutMoreTitle = styled.h5`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: flex-end;

    color: #ffffff;
    white-space: pre-line;
`;

const InstaIcon = styled(FontAwesomeIcon)`
    width: 16px;
    height: 16px;
`;

const InstaButton = styled.a`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 8px;
    gap: 4px;

    background: #ffffff;
    border-radius: 8px;

    position: absolute;
    right: 16px;
    bottom: 16px;

    text-decoration: none;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;

    color: #000000;

    transition: 0.2s;

    &.free {
        padding: 8px;
        gap: 16px;

        font-weight: 800;
        font-size: 20px;
        line-height: 24px;

        position: relative;
        right: auto;
        bottom: auto;

        align-self: stretch;

        border-radius: 16px;

        ${InstaIcon} {
            width: 32px;
            height: 32px;
        }
    }
`;

const LargeCard = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 32px;
    gap: 30px;

    position: relative;

    width: 582px;
    height: 389px;

    background-color: ${({ colour }) => (colour ? colour : "#ffffff")};
    border-radius: 16px;
    transition: 0.2s;

    &:hover {
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
        transform: scale(1.05);
    }
`;

const LargeCardContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 244px;
    height: 325px;
`;

const LargeCardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 8px;

    align-self: stretch;
`;

const LargeCardContentTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 76px;
    text-align: center;

    color: #ffffff;
    text-shadow: 4px 0px 0px #f178a3;

    align-self: stretch;
    white-space: pre-line;

    &.en {
        font-size: 32px;
        line-height: 38px;
    }
`;

const LargeCardContentDate = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    text-align: center;

    color: #6f4c52;

    align-self: stretch;
`;

const LargeCardContentTime = styled.h3`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    color: #6f4c52;

    align-self: stretch;
`;

const LargeCardImageMask = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex-grow: 1;
    border-radius: 16px;
    overflow: hidden;
`;

const LargeCardImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
    transform: scale(1.1) translateY(-6.5px);
`;

const SmallCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 30px;

    position: relative;

    width: 276px;
    height: 184px;

    background-color: #ffffff;

    &.want-more {
        background: linear-gradient(
            180deg,
            #ffffff 0%,
            #ffffff 50%,
            #000000 50%,
            #000000 100%
        );
        h1 {
            font-family: "Pretendard Variable";
            font-style: normal;
            font-weight: 900;
            font-size: 28px;
            line-height: 33px;

            color: #000000;
            span.white {
                color: #ffffff;
            }
        }
    }
    &.undefined {
        h1 {
            font-family: "Pretendard Variable";
            font-style: normal;
            font-weight: 900;
            font-size: 32px;
            line-height: 38px;

            color: #000000;
            white-space: pre-line;
        }
    }

    border-radius: 16px;
    transition: 0.2s;

    &:hover {
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
        transform: scale(1.05);

        ${InstaButton} {
            transform: scale(1.5);
            transform-origin: bottom right;
        }
    }
`;

const ClubSection = styled.section`
    margin: 100px auto;
    gap: 64px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ClubTitleContainer = styled.div`
    width: 754px;
    height: 141px;
    position: relative;
`;

const ClubMainTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 76px;

    color: #000000;

    position: absolute;

    &.ko {
        left: calc(50% - 291px / 2 - 23.5px);
        top: 39px;
    }

    &.en {
        left: calc(50% - 417px / 2 - 6px);
        top: 35px;
    }
`;

const ClubSubTitle = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;

    color: #000000;

    position: absolute;

    &.you {
        left: calc(50% - 201px / 2 - 265.5px);
        top: 13px;
    }

    &.only.ko {
        right: 0px;
        top: 103px;
    }

    &.only.en {
        right: 0px;
        top: 100px;
    }

    strong {
        box-sizing: border-box;
        padding: 2px 8px;
        background: #92b4ec;
        border-radius: 18px;
    }
`;

const StarIcon = styled(FontAwesomeIcon)`
    position: absolute;
    left: calc(50% - 16px / 2 - 369px);
    top: 6px;

    transform: rotate(-36deg);
`;

const Dot = styled.svg`
    position: absolute;
    width: 4px;
    height: 4px;
    left: ${({ position }) =>
        position === "1"
            ? "calc(50% - 4px / 2 + 139px)"
            : position === "2"
            ? "calc(50% - 4px/2 + 166px)"
            : "auto"};
    top: 100px;
    stroke-width: 2;
`;

const Clubs = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: stretch;
    gap: 30px;
`;

const ClubContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    a {
        font-family: "Pretendard Variable";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-decoration: none;
        color: #000000;
    }
`;

const Club = styled.li`
    background-image: url(${({ src }) => (src ? src : "")});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 378px 277px;
    width: 378px;
    height: 277px;
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;

    transition: 0.2s;

    &:hover {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        transform: scale(1.1);

        ${InstaButton} {
            transform: scale(1.5);
            transform-origin: bottom right;
        }
    }
`;

function HomePage() {
    const { t, i18n } = useTranslation(["HomePage"]);
    return (
        <StyledMain>
            <BannerSection>
                <Image src="/logo.png" alt="logo" />
                <TitleContainer>
                    <StyledSubTitle>{t("sub-title-1")}</StyledSubTitle>
                    <StyledTitle>UCLKS</StyledTitle>
                    <StyledSubTitle>{t("sub-title-2")}</StyledSubTitle>
                </TitleContainer>
            </BannerSection>

            <AnnoucementSection>
                <FindOutMore>
                    {/* <FindOutMoreTitle>{t("want-more")}</FindOutMoreTitle> */}
                    <SmallCard className="want-more">
                        <h1>
                            <span>{t("what")}</span>
                            <br />
                            <span className="white">{t("want-more")}</span>
                        </h1>
                        <InstaButton href="https://www.instagram.com/uclksoc/">
                            {t("learn-more")}
                            <InstaIcon icon={faInstagram} size="2x"></InstaIcon>
                        </InstaButton>
                    </SmallCard>
                </FindOutMore>

                <NextEvent>
                    <NextEventTitle>{t("next-event")}</NextEventTitle>
                    <SmallCard className="undefined">
                        <h1>{t("hmm")}</h1>
                    </SmallCard>
                </NextEvent>

                <ComingEvent>
                    <ComingSoonTitle>{t("dont-miss")}</ComingSoonTitle>
                    <LargeCard src="/uclks-freshman.jpg" colour="#f4efcf">
                        <LargeCardImageMask>
                            <LargeCardImage
                                src="/freshman-welcome-party.jpeg"
                                alt="freshman-welcome-party"
                            />
                        </LargeCardImageMask>
                        <LargeCardContentContainer>
                            <LargeCardContent>
                                <LargeCardContentTitle
                                    className={i18n.language}
                                >
                                    {t("freshman-welcome-party.title")}
                                </LargeCardContentTitle>
                                <LargeCardContentDate>
                                    {t("freshman-welcome-party.date")}
                                </LargeCardContentDate>
                                <LargeCardContentTime>
                                    6pm ~ 10pm
                                </LargeCardContentTime>
                            </LargeCardContent>
                            <InstaButton
                                href="https://www.instagram.com/p/ChTl6RqPHt-/?utm_source=ig_web_copy_link"
                                className="free"
                            >
                                {t("learn-more")}
                                <InstaIcon
                                    icon={faInstagram}
                                    size="2x"
                                ></InstaIcon>
                            </InstaButton>
                        </LargeCardContentContainer>
                    </LargeCard>
                </ComingEvent>
            </AnnoucementSection>

            <ClubSection>
                {i18n.language === "ko" ? (
                    <ClubTitleContainer>
                        <StarIcon icon={faStar} size="1x"></StarIcon>
                        <ClubSubTitle className="you">
                            당신과 어울리는
                        </ClubSubTitle>
                        <ClubMainTitle className="ko">
                            한인 동아리
                        </ClubMainTitle>
                        <ClubSubTitle className="only ko">
                            오직 UCLKS에서만
                        </ClubSubTitle>
                        <Dot position="1">
                            <circle cx="2" cy="2" r="2" fill="black" />
                        </Dot>
                        <Dot position="2">
                            <circle cx="2" cy="2" r="2" fill="black" />
                        </Dot>
                    </ClubTitleContainer>
                ) : (
                    <ClubTitleContainer>
                        <StarIcon icon={faStar} size="1x"></StarIcon>
                        <ClubSubTitle className="you">
                            Your Suitable
                        </ClubSubTitle>
                        <ClubMainTitle className="en">
                            Korean Clubs
                        </ClubMainTitle>
                        <ClubSubTitle className="only en">
                            <strong>Only</strong>at UCLKS
                        </ClubSubTitle>
                    </ClubTitleContainer>
                )}

                <Clubs>
                    <Club src="/basketball-club.jpg">
                        <InstaButton href="https://www.instagram.com/uclks_basketball/">
                            {t("learn-more")}
                            <InstaIcon icon={faInstagram} size="2x"></InstaIcon>
                        </InstaButton>
                    </Club>
                    <Club src="/football-club.jpg">
                        <InstaButton href="https://www.instagram.com/uclks_footballclub/">
                            {t("learn-more")}
                            <InstaIcon icon={faInstagram} size="2x"></InstaIcon>
                        </InstaButton>
                    </Club>
                    <Club src="/band-club.jpg">
                        <InstaButton href="https://www.instagram.com/uclks_band/">
                            {t("learn-more")}
                            <InstaIcon icon={faInstagram} size="2x"></InstaIcon>
                        </InstaButton>
                    </Club>
                    <Club src="/dance-club.jpg">
                        {/* <InstaButton href="https://www.instagram.com/uclks_band/">
                                {t("learn-more")}
                                <InstaIcon
                                    icon={faInstagram}
                                    size="2x"
                                ></InstaIcon>
                            </InstaButton> */}
                    </Club>
                </Clubs>
            </ClubSection>
        </StyledMain>
    );
}

export default HomePage;
