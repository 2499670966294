import * as constants from "../constants/departmentConstants";

export const departmentsReducer = (state = {}, action) => {
    switch (action.type) {
        case constants.DEPARTMENTS_REQUEST:
            return { loading: true };
        case constants.DEPARTMENTS_SUCCESS:
            return { loading: false, departments: action.payload };
        case constants.DEPARTMENTS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const departmentReducer = (state = {}, action) => {
    switch (action.type) {
        case constants.DEPARTMENT_REQUEST:
            return { loading: true };
        case constants.DEPARTMENT_SUCCESS:
            return { loading: false, department: action.payload };
        case constants.DEPARTMENT_FAIL:
            return { loading: false, error: action.payload };
        case constants.DEPARTMENTS_RESET:
            return {};
        default:
            return state;
    }
};
