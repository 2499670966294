import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Dropdown = styled.div`
    display: none;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;

    position: relative;

    overflow: hidden;

    &::before {
        content: "";
        background: #606060;
        width: 8px;
        height: 8px;
        z-index: 0;
    }
    &::after {
        content: "";
        background: #606060;
        width: 8px;
        height: 8px;
        z-index: 0;
    }
`;

const Icon = styled(FontAwesomeIcon)`
    color: #ffffff;
    position: relative;
`;

const Option = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;

    background: #606060;
    border-radius: 0px 0px 8px 8px;

    padding: 8px 16px;
`;

const Logo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 84px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;

    &:hover {
        ${Dropdown} {
            display: flex;
        }
        ${Logo} {
            ${Icon} {
                @keyframes move {
                    from {
                        right: -40px;
                    }
                    to {
                        right: 0px;
                    }
                }
                animation: 0.2s ease-in-out 1 move;
            }
        }
    }
`;

const Shape = styled.span`
    width: 8px;
    height: 8px;
    background: ${({ colour }) => (colour ? colour : "#ffffff")};
    border-radius: ${({ position }) =>
        position === "left" ? "0px 8px 0px 0px" : "8px 0px 0px 0px"};

    position: absolute;
    left: ${({ position }) => (position === "left" ? "0px" : "none")};
    right: ${({ position }) => (position === "right" ? "0px" : "none")};
    top: 0px;
    z-index: 1;
`;

const StyledLink = styled(Link)`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;

    text-decoration: none;

    display: flex;
    align-items: center;
    text-align: right;

    color: #ffffff;
`;

function Login() {
    const [t] = useTranslation("Header");
    const path = useLocation().pathname;
    return (
        <Container>
            <Logo>
                <Icon icon={faCircleUser} size="2xl" />
            </Logo>
            <Dropdown>
                <Shape
                    position="left"
                    colour={path === "/introduce" && "#92B4EC"}
                />
                <Option>
                    <StyledLink to="/login">{t("login.login")}</StyledLink>
                </Option>
                <Shape
                    position="right"
                    colour={path === "/introduce" && "#92B4EC"}
                />
            </Dropdown>
        </Container>
    );
}

export default Login;
