import {
    faStar as faStarRegular,
    faStarHalfStroke,
} from "@fortawesome/free-regular-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const Star = styled(FontAwesomeIcon)`
    width: ${({ dimension }) => (dimension ? dimension : "32px")};
    height: ${({ dimension }) => (dimension ? dimension : "32px")};
    color: #ffd24c;

    @media screen and (max-width: 768px) {
        width: ${({ dimension }) => (dimension ? dimension : "16px")};
        height: ${({ dimension }) => (dimension ? dimension : "16px")};
    }
`;

const StarContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 4px;
`;

function Ratings({ rating, dimension }) {
    return (
        <StarContainer>
            {[0, 1, 2, 3, 4].map((num) => (
                <Star
                    key={num}
                    icon={
                        rating >= 1 + num
                            ? faStarSolid
                            : rating >= 0.5 + num
                            ? faStarHalfStroke
                            : faStarRegular
                    }
                    dimension={dimension}
                />
            ))}
        </StarContainer>
    );
}

export default Ratings;
