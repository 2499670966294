import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import NotFound from "./pages/NotFound";
import React from "react";
import GlobalStyles from "./GlobalStyle";
import Header from "./components/Header";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import styled from "styled-components";
import FullFooter from "./components/FullFooter";
import TermsPage from "./pages/TermsPage";
import MembershipPage from "./pages/MembershipPage";
import ConstitutionPage from "./pages/ConstitutionPage";
import CommitteePage from "./pages/CommitteePage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import EmailVerifiedPage from "./pages/EmailVerifiedPage";
import ProfilePage from "./pages/ProfilePage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import PasswordChangedPage from "./pages/PasswordChangedPage";
import IntroducePage from "./pages/IntroducePage";
import ModuleFeedbackPage from "./pages/ModuleFeedbackPage";
import DepartmentPage from "./pages/DepartmentPage";
import FeedbackPage from "./pages/FeedbackPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

const Container = styled.div`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
`;

function App() {
    return (
        <React.Fragment>
            <GlobalStyles />
            <Container>
                <Router>
                    <Header />
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        {/* <Route path="/login" element={<LoginPage />} /> */}
                        {/* <Route
                            path="/forgot-password"
                            element={<ForgotPasswordPage />}
                        /> */}
                        {/* <Route path="/register" element={<RegisterPage />} /> */}
                        {/* <Route path="/profile" element={<ProfilePage />} /> */}
                        {/* <Route
                            path="/verify-email"
                            element={<VerifyEmailPage />}
                        />
                        <Route
                            path="/change-password"
                            element={<ChangePasswordPage />}
                        />
                        <Route
                            path="/change-password/success"
                            element={<PasswordChangedPage />}
                        /> */}
                        <Route path="/introduce" element={<IntroducePage />} />
                        <Route path="/terms" element={<TermsPage />} />
                        <Route
                            path="/privacy"
                            element={<PrivacyPolicyPage />}
                        />
                        <Route path="/committee" element={<CommitteePage />} />
                        <Route
                            path="/constitution"
                            element={<ConstitutionPage />}
                        />
                        <Route
                            path="/membership"
                            element={<MembershipPage />}
                        />
                        <Route
                            path="/module-feedback"
                            element={<ModuleFeedbackPage />}
                        />
                        <Route
                            path="/module-feedback/departments/:id/modules"
                            element={<DepartmentPage />}
                        />
                        <Route
                            path="/module-feedback/departments/:id/modules/:id"
                            element={<FeedbackPage />}
                        />
                        {/* <Route
                            path="/email-verified/:id/:hash"
                            element={<EmailVerifiedPage />}
                        /> */}
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    <FullFooter />
                </Router>
            </Container>
        </React.Fragment>
    );
}

export default App;
