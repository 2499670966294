import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
    position: relative;
`;

const Current = styled.h1`
    text-align: right;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 43px;
    color: #000000;
    width: ${({ width }) => (width ? width : "auto")};
`;

const Icon = styled(FontAwesomeIcon)`
    color: #000000;
    position: relative;

    @media screen and (max-width: 768px) {
        height: 16px;
    }
`;

const Button = styled.button`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 32px;
    gap: 16px;

    border: 3px solid #606060;
    border-radius: 16px;

    position: relative;

    cursor: pointer;

    background: transparent;

    @media screen and (max-width: 768px) {
        border: 2px solid #606060;
        padding: 8px 16px;
    }

    &.clicked {
        ${Icon} {
            &.flip {
                @keyframes flip {
                    from {
                        top: 10px;
                        opacity: 0%;
                    }
                    to {
                        top: 0px;
                        opacity: 100%;
                    }
                }
                animation-duration: 0.2s;
                animation-timing-function: ease-in-out;
                animation-delay: 0s;
                animation-iteration-count: 1;
                animation-direction: normal;
                animation-fill-mode: forwards;
                animation-play-state: running;
                animation-name: flip;
                transform: scaleY(-1);
            }
        }
    }
`;

const Options = styled.div`
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px 32px;
    box-sizing: border-box;
    gap: 16px;

    position: absolute;
    width: 100%;
    left: 0px;
    top: 73px;

    border: 3px solid #606060;
    border-radius: 16px;
    background: #ffffff;
    z-index: 1;

    &.active {
        display: flex;
    }

    @media screen and (max-width: 768px) {
        top: 51px;
        border: 2px solid #606060;
        padding: 8px 16px;
        gap: 8px;
    }
`;

const Option = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: stretch;

    @media screen and (max-width: 768px) {
        gap: 8px;
    }
`;

const OptionText = styled.p`
    text-align: left;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;

    align-self: stretch;

    color: #606060;

    &:hover {
        color: #000000;
        cursor: pointer;
    }

    @media screen and (max-width: 768px) {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
    }
`;

const Divider = styled.div`
    height: 3px;
    background: #606060;
    border-radius: 2px;
    align-self: stretch;

    @media screen and (max-width: 768px) {
        height: 2px;
    }
`;

const OptionSelect = {
    Container,
    Current,
    Options,
    Option,
    OptionText,
    Icon,
    Button,
    Divider,
};

export default OptionSelect;
