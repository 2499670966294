import styled from "styled-components";
import Input from "../components/Styles/Input.styled";
import MoveTo from "../components/Styles/MoveTo.styled";
import { useCallback, useEffect, useState } from "react";
import { Button } from "../components/Styles/Button.styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../actions/userActions";
import { useNavigate } from "react-router-dom";

const StyledMain = styled.main`
    box-sizing: border-box;
    margin: 0 auto;
    padding: 64px 0;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 64px 122px;
    gap: 30px;

    max-width: 582px;
    border-radius: 32px;
    background: #f2f2f2;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
`;

const StyledTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 57px;
    color: #000000;
`;

const StyledNote = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 21px;

    color: #000000;
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

function ForgotPasswordPage() {
    const navigate = useNavigate();
    const dispath = useDispatch();
    const userLogin = useSelector((state) => state.userLogin);
    const { error, loading, isLoggedIn } = userLogin;

    const { t } = useTranslation("LoginPage");

    const [email, setEmail] = useState("");
    const [isEmail, setIsEmail] = useState("");

    const [enabled, setEnabled] = useState(false);

    const handleForgotPassword = (e) => {
        e.preventDefault();
        dispath(forgotPassword(email));
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);
        if (e.target.value === "") {
            setIsEmail(false);
        } else {
            setIsEmail(true);
        }
    };

    const checkForm = useCallback(() => {
        if (!isEmail) {
            setEnabled(false);
        } else {
            setEnabled(true);
        }
    }, [isEmail]);

    useEffect(() => {
        if (isLoggedIn) {
            navigate("/");
        }
        checkForm();
    }, [email, checkForm, isLoggedIn, navigate]);

    return (
        <StyledMain>
            <Container>
                <StyledTitle>비밀번호 찾기</StyledTitle>
                <StyledNote>
                    비밀번호 재설정을 위한 이메일을 입력해 주세요
                </StyledNote>
                <StyledForm action="">
                    <Input.Container>
                        <Input.Content>
                            <Input.StyledLabel htmlFor="email">
                                {t("email")}
                            </Input.StyledLabel>
                            <Input.Wrap>
                                <Input.Field
                                    type="email"
                                    id="email"
                                    placeholder="username@email.com"
                                    onChange={handleEmail}
                                />
                            </Input.Wrap>
                            {error && error.email ? (
                                <Input.Message>{t(error.email)}</Input.Message>
                            ) : (
                                <Input.Message>{t(error)}</Input.Message>
                            )}
                        </Input.Content>
                    </Input.Container>
                    <Input.Container>
                        <Button
                            type="submit"
                            value="비밀번호 재설정"
                            onClick={handleForgotPassword}
                            disabled={!enabled ? 1 : 0}
                        />
                    </Input.Container>
                </StyledForm>
                <MoveTo.Container>
                    <MoveTo.Message>{t("no-account")}</MoveTo.Message>
                    <MoveTo.StyledLink to="/register">
                        {t("register")}
                    </MoveTo.StyledLink>
                </MoveTo.Container>
            </Container>
        </StyledMain>
    );
}

export default ForgotPasswordPage;
